"use client";

import React, { useMemo } from "react";
import { cn, screenInnerHeight, getRating, lgBreakPoint } from "@/lib/utils";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { HotelSummary } from "@/generated/search.openapi";
import {
  getApproximatePercentile,
  getPercentileNumber,
} from "@/app/[bookingType]/search/[searchId]/helper";

type Props = {
  hotel: HotelSummary;
  isMapCard?: boolean;
  className?: string;
};

const HotelExploreCard: React.FC<Props> = ({ hotel, isMapCard, className }) => {
  const smallerHeightDevice = screenInnerHeight < 1000;

  const imageGalleryItems: ReactImageGalleryItem[] = useMemo(() => {
    const image = hotel.images?.[0];
    if (!image) return [];
    return [
      {
        original: image.url,
        lazyLoad: true,
        infinite: true,
        renderItem: (item) => (
          <img
            src={item.original}
            alt={item.originalAlt}
            className={cn(
              "w-full cursor-default rounded-lg border border-white border-opacity-10 object-cover",
              isMapCard && smallerHeightDevice
                ? "h-[150px] max-h-[150px]"
                : "h-[300px]"
            )}
          />
        ),
      },
    ];
  }, [hotel.images, isMapCard, smallerHeightDevice]);

  const approximatePercentile = getApproximatePercentile(hotel, true);

  const percentileNumber = getPercentileNumber(hotel, true);

  const isLessThanLg = screenInnerHeight < lgBreakPoint;

  return (
    <div className="mx-auto h-full max-w-[300px] md:min-w-[350px] md:max-w-[350px]">
      <div
        className={cn("flex w-full flex-col gap-3 rounded-lg p-1", className)}
      >
        {imageGalleryItems?.length ? (
          <ImageGallery
            autoPlay={false}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            showBullets={false}
            lazyLoad={true}
            items={imageGalleryItems}
            showNav={!isLessThanLg}
            disableSwipe
            renderLeftNav={(onClickLeft) => (
              <img
                src="/assets/arrow-left-circle.svg"
                alt="left"
                className="absolute left-1 top-1/2 z-[9] h-8 w-8 -translate-y-1/2 transform cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickLeft(e);
                }}
              />
            )}
            renderRightNav={(onClickRight) => (
              <img
                src="/assets/arrow-right-circle.svg"
                alt="right"
                className="absolute right-1 top-1/2 z-[9] h-8 w-8 -translate-y-1/2 transform cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickRight(e);
                }}
              />
            )}
          />
        ) : (
          <div className="mx-4 flex min-h-[300px] items-center justify-center">
            <span className="text-md text-center font-normal text-primary text-opacity-40">
              Image not available
            </span>
          </div>
        )}
        <div className="flex h-[155px] w-full flex-col justify-between gap-1">
          <div className="flex flex-col gap-2">
            <div className="flex w-full items-start justify-between gap-3">
              <div className="flex h-12 flex-col text-base text-primary">
                <span className="line-clamp-2">{hotel.propertyName}</span>
              </div>
              {hotel.averageGuestRating && (
                <div className="mt-0.5 flex flex-col justify-center gap-x-1 text-right text-sm font-normal text-primary">
                  <span className="flex items-center justify-end gap-1">
                    <img
                      src="/assets/star.svg"
                      alt="Star"
                      className="inline h-3 w-3"
                    />
                    {getRating(hotel.averageGuestRating)}
                  </span>
                </div>
              )}
            </div>
            <div className="flex flex-col items-start justify-center self-stretch">
              <div className="line-clamp-1 w-full text-sm font-normal text-neutral-400">
                {hotel.propertyAddress}
              </div>
              <div className="text-sm font-medium text-neutral-400">
                {hotel.chainName} • {hotel.stars}-star hotel
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            {hotel.lowestNightlyPoints && (
              <>
                <div className="flex w-full items-center gap-1 text-neutral-400">
                  <img
                    src="/assets/points-white.svg"
                    alt="points"
                    className="h-4 w-4"
                  />
                  <span className="text-primary">
                    {new Intl.NumberFormat("en-US").format(
                      hotel.lowestNightlyPoints
                    )}
                  </span>
                  <span className="text-xs">
                    /night •{" "}
                    {new Intl.NumberFormat("en-US").format(hotel.lowestPoints!)}{" "}
                    total
                  </span>
                </div>
                <div
                  className={cn(
                    "flex flex-col items-center justify-start rounded-[3px] px-1.5 py-0.5",
                    approximatePercentile === "low"
                      ? "bg-value-bad"
                      : approximatePercentile === "average"
                        ? "bg-value-average"
                        : approximatePercentile === "high"
                          ? "bg-value-good"
                          : ""
                  )}
                >
                  <span className="flex items-center gap-1 whitespace-nowrap text-xs font-normal text-primary">
                    {approximatePercentile === "low"
                      ? "Weak value"
                      : approximatePercentile === "average"
                        ? "Okay value"
                        : "Great Value"}{" "}
                    <span className="text-sm">{percentileNumber}</span>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelExploreCard;
