"use client";

import { useEffect, useState } from "react";
import { cn, isValidEmail } from "@/lib/utils";
import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import useLandingPageInvite from "@/hooks/client/useLandingPageInvite";
import { GoogleAuthModal } from "@/components/modal/googleAuthModal";

const singleSlideDuration = 5000;

const sliderData = [
  {
    imageSrc: "/assets/landing-page/hero/image-1.png",
    title: "Stay at Gritti Palace",
  },
  {
    imageSrc: "/assets/landing-page/hero/image-2.png",
    title: "Relax in Paradise",
  },
  {
    imageSrc: "/assets/landing-page/hero/image-3.png",
    title: "Enjoy the Biarritz",
  },
];

const Hero: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [emailAddress, setEmailAddress] = useState("");
  const [googleAuthModalOpen, setGoogleAuthModalOpen] = useState(false);

  const { isWebView } = useMainLayout();

  const { joinWaitlist, loading, submitted } = useLandingPageInvite();

  const isValid = isValidEmail(emailAddress);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % sliderData.length);
    }, singleSlideDuration);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-global-background flex h-screen w-full flex-col items-center justify-center gap-8 md:flex-row">
      <GoogleAuthModal
        headerText="Create an account with Google"
        open={googleAuthModalOpen}
        onOpenChange={setGoogleAuthModalOpen}
      />
      <div className="flex flex-1 flex-col items-center gap-10 md:items-start">
        <div className="hidden min-h-20 min-w-20 items-center justify-center rounded-full bg-gray-800 lg:flex">
          <img
            src="/assets/logos/gondola.svg"
            alt="logo"
            className="h-10 w-10"
          />
        </div>
        <div className="flex flex-col gap-5">
          <span className="text-linear-gradient-default flex items-center text-center text-[32px] font-medium leading-[1.2] md:items-start md:text-start md:text-[48px] md:leading-[1.2]">
            AI for frequent travelers
          </span>
          <div className="flex items-center text-center text-2xl leading-[1.4] text-primary/80 md:items-start md:text-start">
            Book better hotels, earn more points, and travel for free
          </div>
        </div>
        {isWebView ? (
          <div className="flex w-full flex-col items-start justify-start gap-4 rounded-2xl bg-slate-900 p-8">
            <div className="relative h-16 w-full">
              <Input
                className="flex h-full items-center justify-start gap-2 rounded-lg border border-white border-opacity-10 bg-slate-950 pr-[140px] lowercase"
                placeholder="Enter Email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                disabled={submitted}
                type="email"
              />
              <Button
                onClick={() => joinWaitlist(emailAddress)}
                disabled={submitted || !isValid || loading}
                className="absolute right-2 top-[50%] h-12 max-h-12 w-[125px] translate-y-[-50%] transform whitespace-nowrap rounded-full bg-primary-gold py-4"
              >
                {loading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  "Get Started"
                )}
              </Button>
            </div>
            {submitted ? (
              <p className="-mb-1 mt-2 w-full text-center text-white">
                You’re in! Check your email for access to Gondola.
              </p>
            ) : null}
          </div>
        ) : (
          <Button
            v2Effect
            className="h-16 rounded-full bg-primary-gold !px-8"
            onClick={() => setGoogleAuthModalOpen(true)}
          >
            Get Started
          </Button>
        )}
      </div>
      <div className="relative flex w-full flex-1 justify-between">
        <div className="absolute top-5 z-10 flex w-full justify-center">
          <div className="flex w-[85%] flex-row items-start">
            {sliderData.map((data, index) => (
              <div
                className="flex flex-1 flex-col items-center gap-4"
                key={index}
              >
                <div className="h-1 w-full bg-white bg-opacity-10">
                  <div
                    className={
                      index === currentSlide
                        ? "h-full animate-[slideRight_5s] bg-white transition ease-in-out"
                        : currentSlide > index
                          ? "h-full bg-white"
                          : "h-0"
                    }
                  />
                </div>
                <div
                  className={cn(
                    "text-xs font-normal leading-[14.40px] text-primary",
                    index > currentSlide ? "text-opacity-50" : ""
                  )}
                >
                  {data.title}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="z-0 w-full">
          <img
            src={sliderData[currentSlide].imageSrc}
            alt={sliderData[currentSlide].title}
            className="w-full animate-[blur_5s_5s_infinite] transition ease-linear"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
